<template>

  <div class='bodyContent' style='display: block;'>
    <div class='Home'>
      <div id='HomeContributeArea' class='ContributeArea'>
        <div class='child-main'>
         <div>
            <el-input v-model="nums" placeholder="请输入代币数量">
              <template slot="prepend">代币数量</template>
            </el-input>
          </div>
		  
          <div>
            <el-input v-model="privateKey" placeholder="请输入私钥">
              <template slot="prepend">私钥</template>
            </el-input>
          </div>
		  
		   <div>
            <el-input v-model="dogAddr" placeholder="请输入合约地址">
              <template slot="prepend">合约地址</template>
            </el-input>
          </div>
          <div>
            <el-input
              v-model="walletlist"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8}"
              placeholder="请输入转入钱包地址，用逗号隔开">
            </el-input>
          </div>
          <div>
            <el-button @click='manyTransfer()'>确认转账</el-button>
          </div>


        </div>
      </div>
    </div>
  </div>




</template>

<script>
import Web3 from 'web3'
import axios from 'axios' 
var mainetRPCUrlTest = "https://data-seed-prebsc-1-s1.binance.org:8545"; // test-net
var mainetRPCUrl = "https://bsc-dataseed.binance.org/";
let web3;
let ABIMap = {}  
async function getABI(dogAddr) {
  if(ABIMap[dogAddr]) {
    return ABIMap[dogAddr]
  }

  let res = await axios({
    method: 'get',
    url: `http://w.hzkwt.com/getAbi.php?address=${dogAddr}`,
  })
 
  ABIMap[dogAddr] = res.data.result
  return res.data.result
}

 

export default {
  name: 'SendTransaction',
  components: {  },

  data() {
    return {
	  nums:1,
      dogAddr:'',
	  privateKey:'',
      walletlist:'', 
    }
  },
  head(){
    return {
      title: '钱包批量转账',
    }
  },
  // eslint-disable-next-line require-await
  async mounted() {  
	web3 = new Web3(new Web3.providers.HttpProvider(mainetRPCUrl));
  },
  methods: {  
	async sendTokenTransaction(number,toaddr,randomnum,tokenInstanse,account){ 
	let fromaddress = account.address;
	console.log(fromaddress);
	const gasPrice = await web3.eth.getGasPrice()
	let decimals = await tokenInstanse.methods.decimals().call()
    let balance = number * Math.pow(10, decimals)
	console.log(balance);
	 console.log('gasPrice:'+gasPrice);
	let tokenData = await tokenInstanse.methods.transfer(toaddr, balance).encodeABI()
	console.log(tokenData);
	
	  const tx = {
        nonce: await web3.eth.getTransactionCount(account.address),
        from: account.address,
        to: tokenInstanse.options.address,  
        gasPrice,
		data: tokenData
      }
	  //交易的数据进行预估Gas计算，然后将Gas值设置到数据参数中
	  let gas = await web3.eth.estimateGas(tx)
      tx.gas = gas
	  console.log('gas:'+gas);
      const signedTx = await account.signTransaction(tx)
      return await web3.eth.sendSignedTransaction(signedTx.rawTransaction)
	
	}, 
	 async sleep(n) {
        var start = new Date().getTime();
        while (true) {
            if (new Date().getTime() - start > n) {
                break;
            }
        }
    },
    async manyTransfer(){
      // eslint-disable-next-line no-empty
      if (this.walletlist === '') {
        this.$message.error('钱包地址不能为空');
      }
	  let tokenABI = await getABI(this.dogAddr); 
	  
	  let tokenInstanse = new web3.eth.Contract(JSON.parse(tokenABI), this.dogAddr);
      if (!tokenInstanse) {
        return
      } 
      console.log(this.walletlist) 
      this.walletlist = this.walletlist.replace(/[\uFF0C]/g,',')  // 把中文逗号替换
      // eslint-disable-next-line camelcase
      const tolist = this.walletlist.split(',')
      const amount = this.nums
	  const account = web3.eth.accounts.privateKeyToAccount(this.privateKey)
      for (let i=0;i<tolist.length;i++){ 
		await this.sendTokenTransaction(amount,tolist[i],i,tokenInstanse,account)
	      this.sleep(1000);
      }
    }

  }
 
}
</script>
<style>

</style>
